<template>
  <div>
    <b-spinner
        v-if="spinner"
        variant="primary"
        class="spinners"
    />
    <div v-if="!spinner">
      <b-spinner
          v-show="isProgres"
          variant="primary"
          class="my-spinner"
      />
      <hr v-if="!spinner">
      <b-row
          v-show="!isProgres"
          class="mt-1"
      >
        <b-col v-if="!isServis" lg="8" md="6" sm="12" cols="12">
          <b-card-actions
              class="cari-liste-card"
          >
            <b-row class="mt-50 mb-3">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block">Birim Listesi</h4>
                </b-col>
              </div>
            </b-row>
            <hr class="mt-0">
            <table class="table table-bordered mt-1">
              <thead>
              <tr>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                  BİRİM ADI
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                  KAYITLI SERVİS SAYISI
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}"/>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(item, index) in birimListesi"
                  :key="index"
                  class="text-center"
              >
                <td>{{ item.detay.birimAdi }}</td>
                <td>{{ item.toplamServis }}</td>
                <td>
                  <b-button
                      variant="bgInfo"
                      class="btn-icon rounded-circle mr-1"
                      size="sm"
                      @click="servisGet(item)"
                  >
                    <feather-icon icon="ListIcon"/>
                  </b-button>
                  <b-button
                      variant="bgDanger"
                      class="btn-icon rounded-circle"
                      size="sm"
                      @click="birimRemove(item)"
                  >
                    <feather-icon icon="TrashIcon"/>
                  </b-button>
                </td>
              </tr>
              </tbody>
            </table>
          </b-card-actions>
        </b-col>
        <b-col
            v-else
            lg="8" md="6" sm="12" cols="12"
        >
          <b-card-actions
              class="cari-liste-card"
          >
            <b-row class="mt-50 mb-3">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block">Servis Listesi</h4>
                </b-col>
              </div>
            </b-row>
            <hr class="mt-0">
            <table class="table table-bordered mt-1">
              <thead>
              <tr>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                  SERVİS ADI
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                  KAYITLI PERSONELLER
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}"/>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(item, index) in servisListesi"
                  :key="index"
                  class="text-center"
              >
                <td>{{ item.detay.altBirimAdi }}</td>
                <td>
                  <b-badge
                      v-for="(user, index) in item.personelListesi"
                      :key="index"
                      class="mr-1 mt-1"
                      variant="bgPrimary"
                  >
                    {{ user.personelAdi }} |
                    <a
                        href="javascript:void(0)"
                        @click="userRemove(user)"
                    >
                      <feather-icon
                          icon="TrashIcon"
                          class="mr-25"
                      />
                      Sil
                    </a>
                  </b-badge>
                </td>
                <td>
                  <b-button
                      v-b-modal.userADD
                      variant="bgInfo"
                      class="btn-icon rounded-circle mr-1"
                      size="sm"
                      @click="userReady(item)"
                  >
                    <feather-icon icon="PlusIcon"/>
                  </b-button>
                  <b-button
                      variant="bgDanger"
                      class="btn-icon rounded-circle"
                      size="sm"
                      @click="servisRemove(item)"
                  >
                    <feather-icon icon="TrashIcon"/>
                  </b-button>
                </td>
              </tr>
              </tbody>
            </table>
          </b-card-actions>
        </b-col>
        <b-col
            v-if="!isServis"
            lg="4" md="6" sm="12" cols="12"
        >
          <b-card-actions
              class="cari-liste-card"
          >
            <b-row class="mt-50 mb-3">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block">Birim Listesi</h4>
                </b-col>
              </div>
            </b-row>
            <hr class="mt-0">
            <b-form-group
                label="Birim Adı"
                label-for="basicInput"
                class="mt-1"
            >
              <b-form-input
                  id="basicInput"
                  v-model="birimAdi"
                  placeholder="Birim Adı Giriniz"
                  @keyup.enter="birimSave()"
                  autocomplete="off"
              />
            </b-form-group>
            <b-row class="odaKayitRow">
              <b-button
                  v-show="!isServis"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="bgInfo"
                  class="mt-1 mr-1 border-0"
                  :style="{backgroundColor:$store.getters.bgSuccess}"
                  @click="birimSave"
              >
                Kaydet
              </b-button>
            </b-row>
          </b-card-actions>
        </b-col>
        <b-col
            v-else
            lg="4" md="6" sm="12" cols="12"
        >
          <b-card-actions
              class="cari-liste-card"
          >
            <b-row class="mt-50 mb-3">
              <div class="card-header-row-btn" :style="{backgroundColor: $store.getters.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block">Servis Kaydı</h4>
                  <b-button
                      v-show="isServis"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="bgInfo"
                      class="myButton border-0 float-right"
                      :style="{backgroundColor:$store.getters.cardTitleButton}"
                      @click="servisToFalse"
                  >
                    <feather-icon
                        icon="ChevronLeftIcon"
                        class="mr-50"
                    />
                    Birim Listesini Göster
                  </b-button>
                </b-col>
              </div>
            </b-row>
            <hr class="mt-50">
            <b-form-group
                label="Servis Adı"
                label-for="basicInput"
                class="mt-1"
            >
              <b-form-input
                  id="basicInput"
                  v-model="altBirimAdi"
                  placeholder="Servis"
                  @keyup.enter="altBirimSave"
                  autocomplete="off"
              />
            </b-form-group>
            <b-row class="odaKayitRow">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="bgInfo"
                  class="mt-1 mr-1 border-0"
                  :style="{backgroundColor:$store.getters.bgSuccess}"
                  @click="altBirimSave"
              >
                Kaydet
              </b-button>
            </b-row>
          </b-card-actions>
        </b-col>
      </b-row>
    </div>
    <b-modal
        id="userADD"
        ok-only
        hide-footer
        centered
        size="lg"
        hide-header
        @hide="userAddHide"
    >
      <h1 class="text-center mt-2">
        Personel Kayıt
      </h1>
      <h6 class="text-center mt-2">
        {{ useAltBirimAdi }}
      </h6>
      <b-row>
        <b-col cols="6">
          <b-form-group
              label="Personel Adı"
              label-for="basicInput"
          >
            <b-form-input
                v-model="personelAdi"
                placeholder="Personel Adını Giriniz"
                autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label="Fotoğraf"
              label-for="basicInput"
          >
            <b-form-file
                type="file"
                placeholder="Dosya Seçiniz"
                drop-placeholder="Drop file here..."
                accept=".jpg"
                :file-name-formatter="formatNames"
                @change="onFileSelected"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
              label="Özgeçmiş"
              label-for="basicInput"
          >
            <quill-editor
                v-model="ozgecmis"
                :options="snowOption"
            />
          </b-form-group>
        </b-col>
        <b-col cols="10">
          <b-progress
              v-model="value"
              max="100"
          />
        </b-col>
        <b-col cols="2">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="margin-0-auto border-0"
              :style="{backgroundColor:$store.getters.bgSuccess}"
              variant="bgInfo"
              @click="userAdd"
          >
            Kaydet
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BSpinner,
  BProgress,
  BRow,
  BCol,
  BCard,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormCheckbox,
  BFormCheckboxGroup,
  BTable,
  BModal,
  BImg,
  BInputGroup,
  BContainer,
  BFormInput,
  BPagination,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BFormFile,
} from 'bootstrap-vue'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    BCardActions,
    BSpinner,
    BProgress,
    BButton,
    BContainer,
    BAvatar,
    BCard,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BImg,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormCheckbox,
    BFormCheckboxGroup,
    BTable,
    BModal,
    BInputGroup,
    quillEditor,
    BFormFile,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      spinner: true,
      snowOption: {
        placeholder: '',
      },
      isServis: false,
      isProgres: false,
      birimAdi: '',
      altBirimAdi: '',
      useAltBirimAdi: '',
      useAltBirimID: '',
      birimID: '',
      personelAdi: '',
      selected: [],
      ozgecmis: '',
      value: 0,
      serviceID: 0,
    }
  },
  computed: {
    servisListesi() {
      return this.$store.getters.servisListesiReturn
    },
    birimListesi() {
      return this.$store.getters.birimListesiReturn
    },
  },
  created() {
    this.$store.dispatch('birim')
        .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
          this.spinner = false
          const mesaj = this.$store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Başarılı',
                text: mesaj,
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            }, { position })
          }
        })
        .catch(err => {
          this.spinner = false
          const mesaj = this.$store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
  },
  beforeDestroy() {
    this.$store.commit('clearBirim')
  },
  methods: {
    userAdd() {
      const fd = new FormData()
      fd.append('resim', this.selected[0])
      fd.append('crm_token', localStorage.getItem('otelUserToken'))
      fd.append('serviceName', 'web')
      fd.append('methodName', 'servisListesi')
      fd.append('personelKaydi', 'ok')
      fd.append('birimID', this.birimID)
      fd.append('personelAdi', this.personelAdi)
      fd.append('altBirimID', this.useAltBirimID)
      fd.append('ozgecmis', this.ozgecmis)
      axios
          .post(store.state.POST_URL, fd, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: function (progressEvent) {
              this.value = parseInt(
                  Math.round((progressEvent.loaded / progressEvent.total) * 95),
              )
            }.bind(this),
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            this.$store
                .dispatch('servis', {
                  birimID: this.serviceID,
                })
            this.$bvModal.hide('userADD'),
                (this.value = 0),
                (this.selected = []),
                (this.personelAdi = ''),
                (this.ozgecmis = ''),
                (this.zemin = {
                  title: 'Siyah Zemin',
                  id: 0
                })
            const mesaj = this.$store.getters.notificationSettings(res.data)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Başarılı',
                text: mesaj,
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            }, { position })
          })
          .catch(err => {
            (this.value = 0)
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
          .then(() => {
            this.$store.dispatch('servis', { birimID: this.birimID })
            this.$bvModal.hide('userADD')
          })
    },
    userReady(val) {
      this.useAltBirimAdi = val.detay.altBirimAdi
      this.useAltBirimID = val.detay.altBirimID
    },
    userAddHide() {
      this.ozgecmis = ''
      this.personelAdi = ''
      this.selected = []
    },
    servisToFalse() {
      this.$store.dispatch('birim')
      this.personelAdi = ''
      this.selected = []
      this.ozgecmis = ''
      this.isProgres = true
      this.birimID = ''
      this.altBirimAdi = ''
      this.$store.commit('clearServis')
      setTimeout(() => {
        this.isServis = false
        this.isProgres = false
      }, 300)
    },
    servisGet(item) {
      this.serviceID = item.detay.birimID,
          this.birimAdi = ''
      this.isServis = true
      this.birimID = item.detay.birimID,
          this.$store
              .dispatch('servis', {
                birimID: item.detay.birimID,
              })
    },
    altBirimSave() {
      if (this.altBirimSave) {
        this.$store
            .dispatch('servisSave', {
              birimID: this.birimID,
              altBirimAdi: this.altBirimAdi,
            })
            .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
              (this.altBirimAdi = '')
              const mesaj = this.$store.getters.notificationSettings(res)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            })
            .catch(err => {
              const mesaj = this.$store.getters.notificationSettings(err)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Servis Adı Giriniz',
            icon: 'ThumbsDownIcon',
            variant: 'danger',
          },
        })
      }
    },
    servisRemove(val) {
      this.$store
          .dispatch('servisRemove', {
            servisID: val.detay.altBirimID,
            birimID: val.detay.birimID
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            const mesaj = this.$store.getters.notificationSettings(res)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Başarılı',
                text: mesaj,
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            }, { position })
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    birimSave() {
      if (this.birimAdi) {
        this.$store
            .dispatch('birimSave', {
              birimAdi: this.birimAdi,
            })
            .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
              (this.birimAdi = '')
              const mesaj = this.$store.getters.notificationSettings(res)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            })
            .catch(err => {
              const mesaj = this.$store.getters.notificationSettings(err)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Birim Adı Giriniz',
            icon: 'ThumbsDownIcon',
            variant: 'danger',
          },
        })
      }
    },
    birimRemove(val) {
      this.$store
          .dispatch('birimRemove', { birimID: val.detay.birimID })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            const mesaj = this.$store.getters.notificationSettings(res)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Başarılı',
                text: mesaj,
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            }, { position })
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    userRemove(val) {
      this.$store
          .dispatch('userRemove', {
            personelSil: val.personelID,
            birimID: this.birimID
          })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            const mesaj = this.$store.getters.notificationSettings(res)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Başarılı',
                text: mesaj,
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            }, { position })
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    onFileSelected(event) {
      event.target.files.forEach((image, index) => {
        this.selected.push(image)
      })
    },
    formatNames(files) {
      return files.length === 1
          ? files[0].name
          : `${files.length} resim seçildi`
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style scoped>
.margin-0-auto {
  margin: 0 auto !important
}

.table td {
  font-weight: 900 !important;
}

.my-spinner {
  position: absolute;
  margin-left: 47%;
  margin-top: 15%;
  width: 3rem;
  height: 3rem;
}

.odaKayitRow {
  justify-content: end !important;
}

.table td {
  text-align: center;
}

.myColor {
  width: 30px;
  height: 30px;
  border-radius: 14px;
  margin: 0 auto !important;
}

[dir] .table th,
[dir] .table td {
  padding: 0.1rem;
}

.row {
  justify-content: end;
}

[dir] .table th,
[dir] .table td {
  border-top: 1px solid #ebe9f1;
  max-width: 450px !important;
}

.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}

[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  color: white;
  padding: 3px !important;
  border: none;
  text-align: center;
}

[dir] .table th,
[dir] .table td {
  padding: 3px !important;
  border-top: 1px solid #ebe9f1;
  max-width: 450px !important;
}

.my-colsss {
  justify-content: space-between;
  display: flex;
}

.card-body {
  padding: 10px 15px;
}

[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  color: white;
}
</style>
